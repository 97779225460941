<template>
  <div>
    <v-container fluid>
      <v-row v-if="!isDeviceCardPage && !showPriceListSkeleton">
        <v-col v-if="isServicePointCardPage" cols="12" sm="4" md="3">
          <v-select
            v-model.number="selectedTerminalId"
            :items="SPTerminalsActive"
            item-value="id"
            @change="selectTerminal"
            label="Обрати термінал"
            dense
            outlined
            hide-details
          >
            <template v-slot:selection="{ item }"> T{{ item.id }} </template>
            <template v-slot:item="{ item }"> T{{ item.id }} </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-select
            v-model.number="selectedDeviceType"
            :items="uniqueDeviceTypes"
            @change="selectDevice"
            label="Обрати автомат"
            dense
            outlined
            hide-details
          >
            <template v-slot:selection="{ item }">
              {{ getDeviceTitle(item) }}
            </template>
            <template v-slot:item="{ item }">
              {{ getDeviceTitle(item) }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-skeleton-loader
      v-if="showPriceListSkeleton"
      width="970"
      type="table-heading,
            table-thead,
            table-row-divider@3,
            table-tfoot"
    ></v-skeleton-loader>
    <v-data-table
      v-else-if="editedPriceListTableData"
      :headers="headers"
      :items="editedPriceListTableData.data"
      style="width: 970px"
    >
      <template v-slot:header>
        <thead style="background-color: #e3f2fd">
          <tr>
            <th colspan="3"></th>
            <th class="font-weight-regular px-0">
              <div class="my-3">
                <v-autocomplete
                  v-model="selectedPriceList"
                  :items="allPriceLists"
                  @change="comparePriceLists"
                  return-object
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  hide-details
                  clearable
                  label="Обрати прайслист з БД"
                ></v-autocomplete>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
      </template>
      <template v-slot:header.price="{ header }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="text-left">
              <div class="text-h6">{{ header.text }}</div>
              <div style="min-height: 18px">
                <span v-if="devicePriceList.name" class="text-body-2">
                  ID{{ devicePriceList.id }}, "{{ devicePriceList.name }}"
                </span>
              </div>
              <div>
                <v-tooltip top :open-on-focus="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-icon
                        @click="devicePriceListDialog = true"
                        v-bind="attrs"
                        v-on="on"
                        class="ma-1"
                        :color="hover ? 'warning' : 'blue accent-4'"
                      >
                        mdi-database-arrow-up-outline
                      </v-icon>
                    </v-hover>
                  </template>
                  <span>Завантажити прайслист в БД</span>
                </v-tooltip>
                <v-tooltip top :open-on-focus="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn @click="loadDevicePriceListMethod" v-bind="attrs" v-on="on" icon color="transparent">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_12775_78514)">
                            <path
                              d="M7.75391 -0.0625V17H4.83594V-0.0625H7.75391ZM13.1094 -0.0625V2.28125H-0.472656V-0.0625H13.1094Z"
                              :fill="hover ? '#fb8c00' : '#2962FF'"
                            />
                            <path
                              d="M15.3158 14.2605H13.2105V10H11.1053V14.2605H9L12.1579 17.4559L15.3158 14.2605Z"
                              :fill="hover ? '#fb8c00' : '#2962FF'"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_12775_78514">
                              <rect width="16" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Завантажити прайслист з автомата</span>
                </v-tooltip>
              </div>
              <div class="text-body-2 mb-1">
                <div>Дата оновлення:</div>
                <div style="height: 20px"></div>
              </div>
            </div>
          </template>
          <span>В стовпчику відображається ціна продуктів на терміналі</span>
        </v-tooltip>
      </template>
      <template v-slot:header.newPrice="{ header }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="text-left">
              <div class="text-h6">{{ header.text }}</div>
              <div style="min-height: 18px">
                <span v-if="newPriceList" class="text-body-2"> ID{{ newPriceList.id }}, "{{ newPriceList.name }}" </span>
              </div>
              <div>
                <v-tooltip top :open-on-focus="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-icon
                        @click="newPriceListDialog = true"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!newPriceList || !hasObjectChanged(editedNewPriceListData, newPriceList)"
                        class="ma-1"
                        :color="hover ? 'warning' : 'blue accent-4'"
                      >
                        mdi-database-arrow-up-outline
                      </v-icon>
                    </v-hover>
                  </template>
                  <span>Завантажити прайслист в БД</span>
                </v-tooltip>
                <v-tooltip top :open-on-focus="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn @click="saveToDevice" v-bind="attrs" v-on="on" :disabled="!newPriceList" icon color="transparent">
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_12775_78514)">
                            <path
                              d="M7.75391 -0.0625V17H4.83594V-0.0625H7.75391ZM13.1094 -0.0625V2.28125H-0.472656V-0.0625H13.1094Z"
                              :fill="hover && newPriceList ? '#fb8c00' : !newPriceList ? 'rgba(0, 0, 0, 0.38)' : '#2962FF'"
                            />
                            <path
                              d="M16.3158 13.7395H14.2105V18H12.1053V13.7395H10L13.1579 10.5441L16.3158 13.7395Z"
                              :fill="hover && newPriceList ? '#fb8c00' : !newPriceList ? 'rgba(0, 0, 0, 0.38)' : '#2962FF'"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_12775_78514">
                              <rect width="16" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>Відправити прайслист на термінал</span>
                </v-tooltip>
                <v-tooltip top :open-on-focus="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-icon
                        @click="showDeleteModal = true"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!selectedPriceList"
                        class="ma-1"
                        :color="hover ? 'error' : 'blue accent-4'"
                      >
                        mdi-delete-outline
                      </v-icon>
                    </v-hover>
                  </template>
                  <span>Видалити прайслист</span>
                </v-tooltip>
              </div>
              <div class="text-body-2 mb-1">
                <div>Дата оновлення:</div>
                <div style="height: 20px">
                  <template v-if="newPriceList">
                    {{ newPriceList.updated_at | getShortDate }}
                    <sup>{{ newPriceList.updated_at | getShortTime }}</sup>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <span
            >В стовпчику відображається ціна продуктів, яку можна відправити на термінал.<br />Пакети цін можна завантажити з бази
            даних або внести вручну
          </span>
        </v-tooltip>
      </template>
      <template v-if="newPriceList" v-slot:item.newPrice="props">
        <v-edit-dialog
          :return-value.sync="props.item.newPrice"
          save-text="Зберегти"
          cancel-text="Відмінити"
          large
          persistent
          @save="savePrice(props.value, editedPriceListTableData.data.indexOf(props.item))"
          @cancel="cancel"
          @open="open"
          @close="close"
        >
          <div>{{ props.item.newPrice }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Оновити ціну "{{ props.item.name }}"</div>
            <v-text-field v-model="props.item.newPrice" label="Edit" single-line counter autofocus></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <div v-else>
      <v-alert class="text-wrap" dense border="left" text type="warning" width="content">
        Автомат не підключено до жодного термінала
      </v-alert>
    </div>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" left>
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Закрити </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="devicePriceListDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">Введіть назву нового прайслиста:</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newPriceListName"
            @input="$v.newPriceListName.$touch()"
            @blur="$v.newPriceListName.$touch()"
            :error-messages="newPriceListErrors"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="devicePriceListDialog = false" text> Відмінити </v-btn>
          <v-btn @click="saveFromDeviceToDB" color="primary" text> Зберегти </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newPriceListDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">Введіть назву нового прайслиста:</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newPriceListName"
            @input="$v.newPriceListName.$touch()"
            @blur="$v.newPriceListName.$touch()"
            :error-messages="newPriceListErrors"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="newPriceListDialog = false" text> Відмінити </v-btn>
          <v-btn @click="saveToDB" color="primary" text> Зберегти </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="priceListToDeviceDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">Введіть назву нового прайслиста:</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newPriceListName"
            @input="$v.newPriceListName.$touch()"
            @blur="$v.newPriceListName.$touch()"
            :error-messages="newPriceListErrors"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="priceListToDeviceDialog = false" text> Відмінити </v-btn>
          <v-btn @click="saveToDeviceWithChanges" color="primary" text> Зберегти </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmActionModal v-if="selectedPriceList" @closeModal="showSendToDeviceModal = false" :showModal="showSendToDeviceModal">
      <template v-slot:title>Відправити прайслист "{{ selectedPriceList.name }}" на автомат?</template>
      <template v-slot:text>Прайслист буде відправлено без змін</template>
      <template v-slot:buttons>
        <v-btn @click="saveToDeviceWithoutChanges" color="primary" text>Так</v-btn>
        <v-btn @click="showSendToDeviceModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>

    <ConfirmActionModal v-if="selectedPriceList" @closeModal="showDeleteModal = false" :showModal="showDeleteModal">
      <template v-slot:title>Видалити прайслист "{{ selectedPriceList.name }}"?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="deleteItem" color="primary" text>Так</v-btn>
        <v-btn @click="showDeleteModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import cloneDeep from '@/mixins/cloneDeep'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import isEqual from 'lodash/isEqual'
import convertMachinesTypes from '@/mixins/convertDevicesTypes'
import ConvertDate from '@/mixins/convertDate'

export default {
  name: 'PriceList',

  components: {
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
  },

  mixins: [cloneDeep, validationMixin, convertMachinesTypes, ConvertDate],

  validations: {
    newPriceListName: { required },
    /*editedPriceListTableData: {
      data: {
        $each: {
          newPrice: { integer },
        },
      },
    },*/
  },

  data: () => ({
    editedPriceListTableData: null,
    editedNewPriceListData: null,
    selectedPriceList: null,
    newPriceListName: '',
    selectedDeviceType: null,
    selectedTerminalId: null,
    snack: false,
    snackColor: '',
    snackText: '',
    pagination: {},
    devicePriceListDialog: false,
    newPriceListDialog: false,
    priceListToDeviceDialog: false,
    showDeleteModal: false,
    showSendToDeviceModal: false,
    showPriceListSkeleton: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
        width: 48,
        class: 'text-h6',
      },
      {
        text: 'Назва товару',
        align: 'start',
        sortable: false,
        value: 'name',
        divider: true,
        width: 240,
        class: 'text-h6',
      },
      {
        text: 'Ціна на терміналі, грн',
        align: 'center',
        sortable: false,
        value: 'price',
        divider: true,
        width: 250,
      },
      {
        text: 'Оновлена ціна, грн',
        align: 'center',
        sortable: false,
        value: 'newPrice',
        divider: true,
        width: 240,
      },
      {
        text: 'Різниця, грн',
        align: 'center',
        sortable: false,
        value: 'priceDifference',
        divider: true,
        width: 180,
        class: 'text-h6',
      },
    ],
  }),

  computed: {
    ...mapState('priceList', ['allPriceLists', 'devicePriceList', 'newPriceList']),
    ...mapState('servicePoints', ['servicePoint']),
    ...mapState('terminals', ['terminal']),
    ...mapState('devices', ['device']),
    ...mapState('dictionaries', ['machinesTypesDictionary']),

    currentCompanyId() {
      return this.$route.params.id
    },
    currentTerminalId() {
      return (
        this.device?.terminal?.id ||
        this.terminal?.id ||
        (this.servicePoint?.terminals && this.servicePoint?.terminals[0].id) ||
        null
      )
    },
    isServicePointCardPage() {
      return this.$route.name === 'ServicePointCard'
    },
    isTerminalCardPage() {
      return this.$route.name === 'TerminalCard'
    },
    isDeviceCardPage() {
      return this.$route.name === 'DeviceCard'
    },
    SPTerminalsActive() {
      return this.servicePoint.terminals.filter((terminal) => terminal.status === 1)
    },
    uniqueDeviceTypes() {
      if (this.terminal && this.terminal.devices) {
        return [...new Set(this.terminal.devices.map((item) => item.type))]
      } else if (this.servicePoint && this.servicePoint.terminals && this.servicePoint.terminals[0].devices) {
        return [...new Set(this.SPTerminalsActive[0].devices.map((item) => item.type))]
      } else {
        return []
      }
    },
    newPriceListErrors() {
      const errors = []
      if (!this.$v.newPriceListName.$dirty) return errors
      !this.$v.newPriceListName.required && errors.push('Це поле обов"язкове')
      return errors
    },
    // priceErrors() {},
  },

  watch: {
    devicePriceListDialog(val) {
      if (!val) {
        this.newPriceListName = ''
        this.$v.newPriceListName.$reset()
      }
    },
    newPriceListDialog(val) {
      if (!val) {
        this.newPriceListName = ''
        this.$v.newPriceListName.$reset()
      }
    },
    priceListToDeviceDialog(val) {
      if (!val) {
        this.newPriceListName = ''
        this.$v.newPriceListName.$reset()
      }
    },
  },

  mounted() {
    this.initialize()
  },

  beforeDestroy() {
    this.SET_DEVICE_PRICE_LIST(null)
    this.SET_NEW_PRICE_LIST(null)
  },

  methods: {
    ...mapActions('priceList', [
      'loadAllPriceLists',
      'loadDevicePriceList',
      'loadNewPriceList',
      'saveNewPriceList',
      'savePriceListToDevice',
      'deletePriceList',
    ]),
    ...mapActions('terminals', ['loadSelectedTerminal']),
    ...mapMutations('priceList', ['SET_DEVICE_PRICE_LIST', 'SET_NEW_PRICE_LIST']),

    async initialize() {
      if (!this.selectedTerminalId && this.isServicePointCardPage)
        this.selectedTerminalId = (this.terminal && this.terminal.id) || this.SPTerminalsActive[0].id
      if (!this.selectedDeviceType && !this.isDeviceCardPage)
        this.selectedDeviceType =
          (this.terminal.devices && this.terminal.devices[0]?.type) || this.servicePoint?.terminals[0].devices[0].type
      if (!this.devicePriceList)
        await Promise.all([this.loadDevicePriceListMethod(), this.loadAllPriceLists(this.currentCompanyId)])
      if (this.devicePriceList) this.editedPriceListTableData = this.cloneObjectDeep(this.devicePriceList)
      if (this.newPriceList) this.editedNewPriceListData = null
    },
    hasObjectChanged(currentObject, initialObject) {
      return !isEqual(currentObject, initialObject)
    },
    async loadDevicePriceListMethod() {
      if (!this.currentTerminalId) return
      this.showPriceListSkeleton = true
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.currentTerminalId,
        deviceType: this.device?.type || this.selectedDeviceType,
      }
      try {
        await this.loadDevicePriceList(payload)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.showPriceListSkeleton = false
      }
    },
    async comparePriceLists() {
      if (!this.selectedPriceList) return
      const payload = {
        companyId: this.currentCompanyId,
        selectedPriceListId: this.selectedPriceList.id,
        devicePriceList: { ...this.devicePriceList },
      }
      delete payload.devicePriceList.id
      if (this.selectedPriceList) await this.loadNewPriceList(payload)
      if (this.newPriceList) {
        this.editedNewPriceListData = this.cloneObjectDeep(this.newPriceList)
        this.calculatePriceDifferences(this.devicePriceList.data, this.newPriceList.data)
      } else await this.initialize()
    },
    calculatePriceDifferences(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        const price1 = parseFloat(arr1[i].price)
        const price2 = parseFloat(arr2[i].price)
        const priceDifference = price2 - price1
        this.$set(this.editedPriceListTableData.data[i], 'newPrice', price2)
        this.$set(this.editedPriceListTableData.data[i], 'priceDifference', priceDifference)
      }
    },
    resetNewPrice() {
      for (let i = 0; i < this.devicePriceList.data.length; i++) {
        this.$set(this.editedPriceListTableData.data[i], 'newPrice', null)
        this.$set(this.editedPriceListTableData.data[i], 'priceDifference', null)
      }
    },
    selectTerminal() {
      this.selectedDeviceType = null
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.selectedTerminalId,
      }
      this.loadSelectedTerminal(payload)
    },
    async selectDevice() {
      await this.loadDevicePriceListMethod()
      await this.initialize()
      await this.comparePriceLists()
    },
    async saveFromDeviceToDB() {
      this.$v.newPriceListName.$touch()
      if (this.$v.$anyError) return
      this.devicePriceListDialog = false
      const payload = this.cloneObjectDeep(this.devicePriceList)
      payload.name = this.newPriceListName
      payload.companyId = this.currentCompanyId
      delete payload.id
      await this.saveNewPriceList(payload)
      this.newPriceListName = ''
      this.selectedPriceList = null
    },
    async saveToDB() {
      this.$v.newPriceListName.$touch()
      if (this.$v.$anyError) return
      if (this.newPriceListDialog) this.newPriceListDialog = false
      const payload = this.cloneObjectDeep(this.editedNewPriceListData)
      payload.name = this.newPriceListName
      payload.companyId = this.currentCompanyId
      delete payload.id
      await this.saveNewPriceList(payload)
      this.newPriceListName = ''
      this.selectedPriceList = null
    },
    async saveToDevice() {
      if (this.hasObjectChanged(this.editedNewPriceListData, this.newPriceList)) {
        this.priceListToDeviceDialog = true
      } else {
        this.showSendToDeviceModal = true
      }
    },
    async saveToDeviceWithoutChanges() {
      this.priceListToDeviceDialog = false
      this.showSendToDeviceModal = false
      const payload = {
        id: this.newPriceList.id,
        companyId: this.currentCompanyId,
        terminalId: this.currentTerminalId,
      }
      try {
        await this.savePriceListToDevice(payload)
        await this.loadDevicePriceListMethod()
      } catch (e) {
        return e
      }
      await this.initialize()
      await this.comparePriceLists()
      this.selectedPriceList = null
    },
    async saveToDeviceWithChanges() {
      this.$v.newPriceListName.$touch()
      if (this.$v.$anyError) return
      await this.saveToDB()
      await this.saveToDeviceWithoutChanges()
    },
    deleteItem() {
      this.showDeleteModal = false
      const payload = {
        companyId: this.currentCompanyId,
        selectedPriceListId: this.selectedPriceList.id,
      }
      this.deletePriceList(payload)
      this.selectedPriceList = null
      this.SET_NEW_PRICE_LIST(null)
      this.resetNewPrice()
    },
    async savePrice(value, index) {
      this.editedNewPriceListData.data[index].price = value
      const priceDifference = +value - +this.devicePriceList.data[index].price
      this.$set(this.editedPriceListTableData.data[index], 'priceDifference', priceDifference)
    },
    cancel() {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    open() {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Оновлення прайслиста'
    },
    close() {
      // this.$v.props.item.newPrice.$reset()
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-data-table-header {
  th {
    background-color: #f3faff;
  }
  & + tbody tr:nth-child(even) {
    background-color: #eeeeee !important;
  }
}
</style>
